import React from "react";
import Logo from "../Assets/logo.png";
import { SiLinkedin } from "react-icons/si";
import { FaInstagram } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";

const Footer = () => {
  return (
    <div className="footer-wrapper">
      <div className="footer-section-one">
        <div className="footer-logo-container">
          <img src={Logo} alt="" />
        </div>
        <div className="footer-icons">
          <a href="https://www.linkedin.com/company/100-cleaners/">
            <SiLinkedin />
          </a>
          <a href="https://www.instagram.com/100cleaners.zw?igsh=MTVxdXNzdGNuc3Rxcg%3D%3D&utm_source=qr">
            <FaInstagram />
          </a>
          <a href="https://wa.me/message/AZCQ5DL53CEDN1">
            <FaWhatsapp />
          </a>
        </div>
      </div>
      <div className="footer-section-two">
        {/*<div className="footer-section-columns">
          <span>Qualtiy</span>
          <span>Help</span>
          <span>Share</span>
          <span>Carrers</span>
          <span>Testimonials</span>
          <span>Work</span>
        </div>*/}
        <div className="footer-section-columns">
          <span>77-572-3370</span>
          <span>support@100cleaners.live</span>
          <span>45 Tunsgate, Mt Pleasant, Harare</span>
          <span>@100cleaners.zw</span>
        </div>
        <div className="footer-section-columns">
          <span>Terms & Conditions</span>
          <span>Privacy Policy</span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
