import React from "react";
import AboutBackground from "../Assets/about-background.png";
import AboutBackgroundImage from "../Assets/about-background-image.png";


const About = () => {
  return (
    <div className="about-section-container">
      <div className="about-background-image-container">
        <img src={AboutBackground} alt="" />
      </div>
      <div className="about-section-image-container">
        <img src={AboutBackgroundImage} alt="" />
      </div>
      <div className="about-section-text-container">
        <p className="primary-subheading">About</p>
        <h1 className="primary-heading">
          We are a laundry service located in Harare, Zimbabwe.
        </h1>
        <p className="primary-text">
          We are located in Mt Pleasant, Harare, Zimbabwe. We offer a wide range of services
        </p>
        <p className="primary-text">
          We do from wash, fold & iron to dry cleaning, we also offer pick up and delivery.
        </p>
        <div className="about-buttons-container">
          <a href="https://wa.me/message/AZCQ5DL53CEDN1">
            <button className="secondary-button">Learn More</button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default About;
