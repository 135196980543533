import React from "react";
import BannerBackground from "../Assets/home-banner-background.png";
import BannerImage from "../Assets/home-banner-image.png";
import Navbar from "./Navbar";
import { FiArrowRight } from "react-icons/fi";

const Home = () => {
  return (
    <div className="home-container">
      <Navbar />
      <div className="home-banner-container">
        <div className="home-bannerImage-container">
          <img src={BannerBackground} alt="" />
        </div>
        <div className="home-text-section">
          <h1 className="primary-heading">
            100% Cleaners <br/> All your laundry needs in one place
          </h1>
          <p className="primary-text">
            We provide the best laundry services in town. We provide laundry
            services for all types of clothes and fabrics. We also provide
            express delivery services.
          </p>
          <a href="tel:+263 775723370">
            <button className="secondary-button">
              Book Now <FiArrowRight />{" "}
            </button>
          </a>
        </div>
        <div className="home-image-section">
          <img src={BannerImage} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Home;
